import axios from 'axios';

export default class ProcedimientoService {

    /** Catalogos */
    getControlaVisibilidad(info){
        const url = process.env.VUE_APP_APIURL + `/api/pacontrolavisibilidad`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getDiaMedida() {            
        const url = process.env.VUE_APP_APIURL + `/api/diamedida`;    
        return axios.get(url).then(res=>res.data);
    }
    getTipoRespuesta() {            
        const url = process.env.VUE_APP_APIURL + `/api/tiporespuesta`;    
        return axios.get(url).then(res=>res.data);
    }
    getMedioImpugnacionConsulta() {            
        const url = process.env.VUE_APP_APIURL + `/api/medioimpugnacionconsulta`;    
        return axios.get(url).then(res=>res.data);
    }
  
    getMedioImpugnacionConsultaDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnaciondetalleconsultadetalle`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


    
    getClasificacion() {            
        const url = process.env.VUE_APP_APIURL + `/api/clasificacionprocedimiento`;    
        return axios.get(url).then(res=>res.data);
    }
    getMateria() {            
        const url = process.env.VUE_APP_APIURL + `/api/materia`;    
        return axios.get(url).then(res=>res.data);
    }
    getCompetencia() {            
        const url = process.env.VUE_APP_APIURL + `/api/competencia`;    
        return axios.get(url).then(res=>res.data);
    }

    /** Acta inspeccion */
    createActaInspeccion(info){
        const url = process.env.VUE_APP_APIURL + `/api/paactainspeccionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getActaInspeccion(info){
        const url = process.env.VUE_APP_APIURL + `/api/paactainspeccionconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteActaInspeccion(info){
        const url = process.env.VUE_APP_APIURL + `/api/paactainspeccionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateActaInspeccion(info){
        const url = process.env.VUE_APP_APIURL + `/api/paactainspeccionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


    createActaInspeccionMateria(info){
        const url = process.env.VUE_APP_APIURL + `/api/paactainspeccionmateriaalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getActaInspeccionMateria(info){
        const url = process.env.VUE_APP_APIURL + `/api/paactainspeccionmateriaconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteActaInspeccionMateria(info){
        const url = process.env.VUE_APP_APIURL + `/api/paactainspeccionmateriabaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateActaInspeccionMateria(info){
        const url = process.env.VUE_APP_APIURL + `/api/paactainspeccionmateriaedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
   
   
  /** Acuerdo Emplazamiento */
    createAcuerdoEmplazamiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/paacuerdoemplazamientoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getAcuerdoEmplazamiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/paacuerdoemplazamientoconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteAcuerdoEmplazamiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/paacuerdoemplazamientobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateAcuerdoEmplazamiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/paacuerdoemplazamientoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

 

    createAcuerdoEmplazamientoFechaContestacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/paacuerdoemplazamientoaltafechacontestacion`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getAcuerdoEmplazamientoFechaContestacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/paacuerdoemplazamientoconsultafechacontestacion`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteAcuerdoEmplazamientoFechaContestacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/paacuerdoemplazamientobajafechacontestacion`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateAcuerdoEmplazamientoFechaContestacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/paacuerdoemplazamientoedicionfechacontestacion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }



     /** Alegatos */
     createAlegatos(info){
        const url = process.env.VUE_APP_APIURL + `/api/paalegatosalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getAlegatos(info){
        const url = process.env.VUE_APP_APIURL + `/api/paalegatosconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteAlegatos(info){
        const url = process.env.VUE_APP_APIURL + `/api/paalegatosbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateAlegatos(info){
        const url = process.env.VUE_APP_APIURL + `/api/paalegatosedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    /** Contestacion Acta Inspeccion */
    createContestacionActaInspeccion(info){
        const url = process.env.VUE_APP_APIURL + `/api/pacontestacionactainspeccionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getContestacionActaInspeccion(info){
        const url = process.env.VUE_APP_APIURL + `/api/pacontestacionactainspeccionconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteContestacionActaInspeccion(info){
        const url = process.env.VUE_APP_APIURL + `/api/pacontestacionactainspeccionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateContestacionActaInspeccion(info){
        const url = process.env.VUE_APP_APIURL + `/api/pacontestacionactainspeccionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    /** Copia Certificada */
    createCopiaCertificada(info){
        const url = process.env.VUE_APP_APIURL + `/api/pacopiacertificadaalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getCopiaCertificada(info){
        const url = process.env.VUE_APP_APIURL + `/api/pacopiacertificadaconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteCopiaCertificada(info){
        const url = process.env.VUE_APP_APIURL + `/api/pacopiacertificadabaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateCopiaCertificada(info){
        const url = process.env.VUE_APP_APIURL + `/api/pacopiacertificadaedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


    /** Levantamiento Sellos */
    createLevantamientoSello(info){
        const url = process.env.VUE_APP_APIURL + `/api/palevantamientoselloalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getLevantamientoSello(info){
        const url = process.env.VUE_APP_APIURL + `/api/palevantamientoselloconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteLevantamientoSello(info){
        const url = process.env.VUE_APP_APIURL + `/api/palevantamientosellobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateLevantamientoSello(info){
        const url = process.env.VUE_APP_APIURL + `/api/palevantamientoselloedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }



     /** Medidas Correctivas */
     createMedidaCorrectiva(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedidacorrectivaalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getMedidaCorrectiva(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedidacorrectivaconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteMedidaCorrectiva(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedidacorrectivabaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateMedidaCorrectiva(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedidacorrectivaedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }



    /** Medidas Seguridad */
    createMedidaSeguridad(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedidaseguridadalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getMedidaSeguridad(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedidaseguridadconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteMedidaSeguridad(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedidaseguridadbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateMedidaSeguridad(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedidaseguridadedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateMedidaSeguridadReiterar(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedidaseguridadedicionreiterar`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    /** Medida Impugnacion Acuerdo Admision */
    createMedidaImpugnacionAcuerdoAdminision(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_acuerdoadmisionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getMedidaImpugnacionAcuerdoAdminision(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_acuerdoadmisionconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteMedidaImpugnacionAcuerdoAdminision(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_acuerdoadmisionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateMedidaImpugnacionAcuerdoAdminision(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_acuerdoadmisionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }



    /** Medida Impugnacion Conmutacion */
    createMedidaImpugnacionConmutacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_conmutacionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getMedidaImpugnacionConmutacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_conmutacionconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteMedidaImpugnacionConmutacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_conmutacionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateMedidaImpugnacionConmutacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_conmutacionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


     /** Medida Impugnacion Juicio Amparo */
     createMedidaImpugnacionJuicioAmparo(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_juicioamparoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getMedidaImpugnacionJuicioAmparo(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_juicioamparoconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteMedidaImpugnacionJuicioAmparo(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_juicioamparobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateMedidaImpugnacionJuicioAmparo(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_juicioamparoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }



    /** Medida Impugnacion Juicio Nulidad */
    createMedidaImpugnacionJuicioNulidad(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_juicionulidadalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getMedidaImpugnacionJuicioNulidad(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_juicionulidadconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteMedidaImpugnacionJuicioNulidad(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_juicionulidadobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateMedidaImpugnacionJuicioNulidad(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_juicionulidadedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


     /** Medida Impugnacion Modificacion Multa */
     createMedidaImpugnacionModificacionMulta(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_modificaciomultaalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getMedidaImpugnacionModificacionMulta(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_modificaciomultaconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteMedidaImpugnacionModificacionMulta(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_modificaciomultabaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateMedidaImpugnacionModificacionMulta(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_modificaciomultaedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


       /** Medida Impugnacion Prevencion */
       createMedidaImpugnacionPrevencion(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_prevencionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getMedidaImpugnacionPrevencion(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_prevencionconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteMedidaImpugnacionPrevencion(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_prevencionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateMedidaImpugnacionPrevencion(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_prevencionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


    
       /** Medida Impugnacion Requerimiento */
       createMedidaImpugnacionRequerimiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_requerimientoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getMedidaImpugnacionRequerimiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_requerimientoconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteMedidaImpugnacionRequerimiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_requerimientobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateMedidaImpugnacionRequerimiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_requerimientoedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


      /** Medida Impugnacion Revision */
      createMedidaImpugnacionRevision(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_revisionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getMedidaImpugnacionRevision(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_revisionconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteMedidaImpugnacionRevision(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_revisionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateMedidaImpugnacionRevision(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacion_revisionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

     /** Medida Impugnacion Revision */
     createMedidaImpugnacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getMedidaImpugnacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteMedidaImpugnacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateMedidaImpugnacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }



     /** Medida Impugnacion Detalle */
     createMedidaImpugnacionDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltadetalle`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getMedidaImpugnacionDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultadetalle`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteMedidaImpugnacionDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajadetalle`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateMedidaImpugnacionDetalle(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionediciondetalle`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

        /** Medida Impugnacion JUICIO AMPARO -A */
        createMedidaImpugnacionjca(info){
            const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltajca`;     
            return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
        }
        getMedidaImpugnacionjca(info){
            const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultajca`;     
            return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
        }
        deleteMedidaImpugnacionjca(info){
            const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajajca`;     
            return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
        }
        updateMedidaImpugnacionjca(info){
            const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionjca`;     
            return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
        }

   /** Medida Impugnacion JUICIO AMPARO -B*/
   createMedidaImpugnacionjcb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltajcb`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionjcb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultajcb`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionjcb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajajcb`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionjcb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionjcb`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}



/** Medida Impugnacion JUICIO AMPARO -C*/
createMedidaImpugnacionjcc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltajcc`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionjcc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultajcc`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionjcc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajajcc`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionjcc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionjcc`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}

/** Medida Impugnacion JUICIO AMPARO -D*/
createMedidaImpugnacionjcd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltajcd`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionjcd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultajcd`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionjcd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajajcd`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionjcd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionjcd`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}




        /** Medida Impugnacion JUICIO NULIDAD -A */
        createMedidaImpugnacionjna(info){
            const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltajna`;     
            return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
        }
        getMedidaImpugnacionjna(info){
            const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultajna`;     
            return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
        }
        deleteMedidaImpugnacionjna(info){
            const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajajna`;     
            return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
        }
        updateMedidaImpugnacionjna(info){
            const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionjna`;     
            return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
        }

  /** Medida Impugnacion JUICIO NULIDAD -B  */
  createMedidaImpugnacionjnb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltajnb`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionjnb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultajnb`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionjnb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajajnb`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionjnb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionjnb`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}



 /** Medida Impugnacion JUICIO NULIDAD -C  */
 createMedidaImpugnacionjnc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltajnc`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionjnc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultajnc`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionjnc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajajnc`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionjnc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionjnc`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}


 /** Medida Impugnacion JUICIO NULIDAD -D  */
 createMedidaImpugnacionjnd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltajnd`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionjnd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultajnd`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionjnd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajajnd`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionjnd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionjnd`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}




 /** Medida Impugnacion JUICIO NULIDAD -E */
 createMedidaImpugnacionjne(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltajne`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionjne(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultajne`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionjne(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajajne`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionjne(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionjne`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}





 /** Medida Impugnacion RECURSO REVISION - A */
 createMedidaImpugnacionrra(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltarra`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionrra(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultarra`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionrra(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajarra`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionrra(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionrra`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}




 /** Medida Impugnacion RECURSO REVISION - B */
 createMedidaImpugnacionrrb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltarrb`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionrrb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultarrb`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionrrb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajarrb`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionrrb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionrrb`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}


 /** Medida Impugnacion RECURSO REVISION - C */
 createMedidaImpugnacionrrc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltarrc`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionrrc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultarrc`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionrrc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajarrc`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionrrc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionrrc`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}

  /** Medida Impugnacion RECURSO REVISION - D */
  createMedidaImpugnacionrrd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltarrd`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionrrd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultarrd`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionrrd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajarrd`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionrrd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionrrd`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}



 /** Medida Impugnacion RECURSO MULTA - A */
 createMedidaImpugnacionrma(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltarma`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionrma(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultarma`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionrma(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajarma`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionrma(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionrma`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}



 /** Medida Impugnacion RECURSO MULTA - B */
 createMedidaImpugnacionrmb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltarmb`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionrmb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultarmb`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionrmb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajarmb`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionrmb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionrmb`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}

 /** Medida Impugnacion RECURSO MULTA - C */
 createMedidaImpugnacionrmc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltarmc`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionrmc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultarmc`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionrmc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajarmc`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionrmc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionrmc`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}


 /** Medida Impugnacion RECURSO MULTA - D */
 createMedidaImpugnacionrmd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltarmd`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionrmd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultarmd`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionrmd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajarmd`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionrmd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionrmd`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}


 /** Medida Impugnacion RECURSO MULTA - E */
 createMedidaImpugnacionrme(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltarme`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionrme(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultarme`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionrme(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajarme`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionrme(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionrme`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}

 /** Medida Impugnacion RECURSO MULTA - F */
 createMedidaImpugnacionrmf(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltarmf`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacionrmf(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultarmf`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacionrmf(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajarmf`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacionrmf(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicionrmf`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}




 /** CONMUTACION MULTA - A */
 createMedidaImpugnacioncma(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltacma`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacioncma(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultacma`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacioncma(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajacma`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacioncma(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicioncma`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}



 /** CONMUTACION MULTA - B */
 createMedidaImpugnacioncmb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltacmb`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacioncmb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultacmb`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacioncmb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajacmb`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacioncmb(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicioncmb`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}

 /** CONMUTACION MULTA - C */
 createMedidaImpugnacioncmc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltacmc`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacioncmc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultacmc`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacioncmc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajacmc`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacioncmc(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicioncmc`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}


 /** CONMUTACION MULTA - D */
 createMedidaImpugnacioncmd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltacmd`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacioncmd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultacmd`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacioncmd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajacmd`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacioncmd(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicioncmd`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}


 /** CONMUTACION MULTA - E */
 createMedidaImpugnacioncme(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltacme`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacioncme(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultacme`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacioncme(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajacme`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacioncme(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicioncme`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}


 
 createMedidaImpugnacioncme_e(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltacme_e`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacioncme_e(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultacme_e`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacioncme_e(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajacme_e`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacioncme_e(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicioncme_e`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}



 /** CONMUTACION MULTA - F */
 createMedidaImpugnacioncmf(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionaltacmf`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
getMedidaImpugnacioncmf(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionconsultacmf`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
deleteMedidaImpugnacioncmf(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionbajacmf`;     
    return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}
updateMedidaImpugnacioncmf(info){
    const url = process.env.VUE_APP_APIURL + `/api/pamedioimpugnacionedicioncmf`;     
    return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
}




      /** Otros */
      createOtros(info){
        const url = process.env.VUE_APP_APIURL + `/api/paotrosalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getOtros(info){
        const url = process.env.VUE_APP_APIURL + `/api/paotrosconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteOtros(info){
        const url = process.env.VUE_APP_APIURL + `/api/paotrosbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateOtros(info){
        const url = process.env.VUE_APP_APIURL + `/api/paotrosedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }



     /** Otros Prorroga */
     createOtrosProrroga(info){
        const url = process.env.VUE_APP_APIURL + `/api/paotrosprorrogaalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getOtrosProrroga(info){
        const url = process.env.VUE_APP_APIURL + `/api/paotrosprorrogaconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteOtrosProrroga(info){
        const url = process.env.VUE_APP_APIURL + `/api/paotrosprorrogabaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateOtrosProrroga(info){
        const url = process.env.VUE_APP_APIURL + `/api/paotrosprorrogaedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


     /** Resolucion */
     createResolucion(info){
        const url = process.env.VUE_APP_APIURL + `/api/paresolucionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getResolucion(info){
        const url = process.env.VUE_APP_APIURL + `/api/paresolucionconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteResolucion(info){
        const url = process.env.VUE_APP_APIURL + `/api/paresolucionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateResolucion(info){
        const url = process.env.VUE_APP_APIURL + `/api/paresolucionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }



     /** Verificacion Medida */
     createVerificacionMedida(info){
        const url = process.env.VUE_APP_APIURL + `/api/paverificacionmedidasalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getVerificacionMedida(info){
        const url = process.env.VUE_APP_APIURL + `/api/paverificacionmedidasconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteVerificacionMedida(info){
        const url = process.env.VUE_APP_APIURL + `/api/paverificacionmedidasbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateVerificacionMedida(info){
        const url = process.env.VUE_APP_APIURL + `/api/paverificacionmedidasedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }



    /** Servicio Procedimiento */
    createOrdenTrabajoServicioProcedimiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/paordentrabajoservicioprocedimientoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getOrdenTrabajoServicioProcedimiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/paordentrabajoservicioprocedimientoconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getOrdenTrabajoServicioProcedimientoPorId(info){
        const url = process.env.VUE_APP_APIURL + `/api/paordentrabajoservicioprocedimientoconsultaporid`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteOrdenTrabajoServicioProcedimiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/paordentrabajoservicioprocedimientobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateOrdenTrabajoServicioProcedimiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/paordentrabajoservicioprocedimientoedicion
        `;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getServicioProcedimiento(info){
        const url = process.env.VUE_APP_APIURL + `/api/paservicioprocedimientoconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }




      /** Medidas Urgente */
      createMedidaUrgente(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedidaurgentealta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getMedidaUrgente(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedidaurgenteconsulta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
     deleteMedidaUrgente(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedidaurgentebaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    updateMedidaUrgente(info){
        const url = process.env.VUE_APP_APIURL + `/api/pamedidaurgenteedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}